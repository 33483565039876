<template>
  <div class="user-page">

    <div class="float-end" v-if="this.$store.state.SAuth?.access?.isAdmin">
      <router-link to="/user/admin/add" class="btn btn-sm btn-secondary">Add user</router-link>
    </div>
    <h1>User management</h1>

    <div class="table-responsive">
      <table class="table">
        <thead class="table-light small">
        <tr>
          <th width="1%">ID</th>
          <th>Login</th>
          <th>E-mail</th>
          <th>Role</th>
          <th>Status</th>
          <th width="1%" class="text-nowrap text-center">Date register</th>
          <th width="1%"></th>
        </tr>
        </thead>
        <tbody v-if="userList?.length > 0">
          <tr v-for="userData in userList" :key="userData.id">
            <td class="text-nowrap">{{ userData.id }}</td>
            <td>{{ userData.login }}</td>
            <td>{{ userData.email }}</td>
            <td>
              <span class="badge bg-secondary">
                {{ userRoleEnum.getLabels()[userData.role] }}
              </span>
            </td>
            <td>
              <span class="badge bg-secondary">
                {{ userStatusEnum.getLabels()[userData.status] }}
              </span>
            </td>
            <td class="text-nowrap text-center small">
              {{ this.getDateTime(userData.dateCreate) }}
            </td>
            <td class="text-nowrap">
              <router-link :to="{path: '/user/admin/edit/' + userData.id}" class="fa fa-pencil-square icon-fa" v-if="this.$store.state.SAuth?.access?.isAdmin"></router-link>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="100%" class="text-center">No records found</td>
          </tr>
        </tbody>
      </table>
    </div>

    <pager-nav :pager="userPager" :route="'/user/admin'" @onPagerUpdate="onPagerUpdate"></pager-nav>

  </div>
</template>

<script>

import PagerNav from "@/components/PagerNav";
import {UserStatusEnum, UserRoleEnum} from "@/modules/user/enums/UserEnum";

export default {
  components: {PagerNav},

  data: () => ({
    userList: [],
    userPager: {},
    userStatusEnum: UserStatusEnum,
    userRoleEnum: UserRoleEnum
  }),

  async mounted() {

    if (!await this.checkManager())
      return;

    this.setBreadcrumbs();

    await this.doUserAdminIndex();
  },

  methods: {

    async onPagerUpdate() {

      await this.doUserAdminIndex();
    },

    async doUserAdminIndex() {

      let apiHolder = await this.sendApiGet(['api/user/admin/index', {
        page: this.$route.query?.page || 1
      }]);

      if (!apiHolder.isSuccess())
        return;

      if (apiHolder.data?.userList)
        this.userList = apiHolder.data.userList;
      if (apiHolder.data?.userPager)
        this.userPager = apiHolder.data.userPager;
    },

    setBreadcrumbs() {

      this.$emit("setBreadcrumbs", [
        ['Admin panel', '/admin'],
        'User management'
      ]);
    }

  }

}
</script>

<style scoped>

</style>